import { Injectable } from '@angular/core';
import { VersionSettingService } from './settings/version-setting.service';
import { PermissionsService } from './permission.service';
import { AuthPermissionService } from './auth/auth-permission.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    versions: any = {};

    constructor(private versionService: VersionSettingService,
        private permissionsService:PermissionsService
    ) { }

    getDashboardMenu() {
        const menu= [
            {
                label: 'Ana Sayfa', icon: 'pi pi-fw pi-home', routerLink: ['/operator/']
            }]
        const response=this.permissionsService.setMenuVisibility(menu);
        return response;
    }

    getVersionDetails() {
        const menu=[
            {
                label: 'Versiyonlar', icon: 'pi pi-fw pi-shield', routerLink: ['/settings/versionSettings']
            }];
        const response=this.permissionsService.setMenuVisibility(menu);
        return response;
    }

   async getFinanceMenu() {
        const menu= [
            {
                label: 'Muhasebe', icon: 'pi pi-fw pi-briefcase',
                items: [
                    /*
                    { label: 'Ödeme Listesi', icon: 'pi pi-fw pi-money-bill', routerLink: ['/operator/payment-list'], visible: this.authPermissionService.permissionCheck('Finance.Payment.List') && this.checkModule("Fiyat Modülü", 'v2') },
                    { label: 'Ödeme Operasyon', icon: 'pi pi-fw pi-money-bill', routerLink: ['/operator/payment-operations'], visible: this.authPermissionService.permissionCheck('Finance.Payment.List') && this.checkModule("Fiyat Modülü", 'v2') },
                    { label: 'Ödeme Mutabakat Eski', icon: 'pi pi-fw pi-verified', routerLink: ['/operator/payment-consensus'], visible: this.authPermissionService.permissionCheck('Finance.Payment.List') && this.checkModule("Fiyat Modülü", 'v2') },
                    */
                    { label: 'Ödeme Mutabakat', icon: 'pi pi-fw pi-verified', routerLink: ['/operator/payment-control'], visible: await this.checkModule("Fiyat Modülü", 'v2') },
                    { label: 'Fatura Takip', icon: 'pi pi-fw pi-ticket', routerLink: ['/operator/bill-list'], visible: await this.checkModule("Fiyat Modülü", 'v2') },

                    /*
                    {
                        label: 'Operasyon Onay', icon: 'pi pi-fw pi-check-circle', routerLink: ['/operator/operation-approve'], visible: this.authPermissionService.permissionCheck('Operation-Approve', 'isList')
                    },
                    {
                        label: 'PO Atama', icon: 'pi pi-fw pi-paperclip', routerLink: ['/operator/po-list'], visible: this.authPermissionService.permissionCheck('Po-List', 'isList')
                    },
                    {
                        label: 'Ödeme Takip', icon: 'pi pi-fw pi-money-bill', routerLink: ['/operator/pay-track'], visible: this.authPermissionService.permissionCheck('Pay-Track', 'isList')
                    },*/
                    //{label: 'Servisler Detay', icon: 'pi pi-fw pi-list', routerLink: ['/supplier-operations/financelist']}
                ]
            },
        ];
        const response=this.permissionsService.setMenuVisibility(menu);
        return response;
    }

    getOperatorMenu() {
        const menu = [
            {
                label: 'Çağrı Karşılama', icon: 'pi pi-fw pi-phone', routerLink: ['/operator/request']
            },
            {
                label: 'Dosya Listesi', icon: 'pi pi-fw pi-list', routerLink: ['/operator/file-list']
            },
            {
                label: 'Hizmet Listesi', icon: 'pi pi-fw pi-list', routerLink: ['/operator/service-list']
            },


            {
                label: 'Operasyon Kontrol', icon: 'pi pi-fw pi-clock', routerLink: ['/operator/operations']
            },



            {
                label: 'İşlemler', icon: 'pi pi-fw pi-th-large',
                items: [
                    {
                        label: 'Şikayet Kaydı', icon: 'pi pi-fw pi-ticket', routerLink: ['/operator/complaint']
                    },
                    // {
                    //     label: 'Manuel Sms', icon: 'pi pi-fw pi-send', routerLink: ['/operator/manual-sms'],
                    // },
                    {
                        label: 'Servis Aktifleştirme', icon: 'pi pi-fw pi-history', routerLink: ['/operator/activateService']
                    },
                    {
                        label: 'Duyurular', icon: 'pi pi-fw pi-megaphone', routerLink: ['/operator/announcements']
                    },
                    {
                        label: 'Bildirim Log Takibi', icon: 'pi pi-fw pi-history', routerLink: ['/log-list'],
                    },
                    {
                        label: "Görev",
                        icon: 'pi pi-fw pi-flag-fill',
                        items: [
                            {
                                label: 'Otomatik Görev Düzenleme',
                                icon: 'pi pi-fw pi-file-edit',
                                routerLink: ['/operator/autoTask'],
                            },
                            {
                                label: 'Görev Listesi',
                                icon: 'pi pi-fw pi-table',
                                routerLink: ['/operator/tasks']

                            },
                        ]
                    },
                    {
                        label: 'Tedarikçi Haritası', icon: 'pi pi-fw pi-map', routerLink: ['/operator/octo-map'],

                    },
                    {
                        label: 'Scriptler', icon: 'pi pi-fw pi-file-edit', routerLink: ['/operator/scripts'],
                    },

                ]
            },
        ];

        /*
        if(this.whiteList.includes(email)) {
            // Yeni fiyat sistemine entegre edene kadar kapatıldı
            //menu[3].items.push({label: 'Fiyat Güncelleme', icon: 'pi pi-fw pi-history', routerLink: ['/operator/pricecorrection'],});
        }*/

        const response=this.permissionsService.setMenuVisibility(menu);
        return response;
    }

    async getSettingsMenu() {
        const menu= [
            {
                label: 'Tanımlar', icon: 'pi pi-fw pi-bookmark',
                items: [
                    {
                        label: 'Genel Tanımlar', icon: 'pi pi-fw pi-tablet', items: [
                            { label: 'Müşteriler', icon: 'pi pi-fw pi-building', routerLink: ['/settings/Customers']},

                            { separator: true },
                            { label: 'Ürünler', icon: 'pi pi-fw pi-box', routerLink: ['/settings/Products'] },
                            { label: 'Müşteri Tipleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/customertype'] },
                            { label: 'Teminatlar', icon: 'pi pi-fw pi-check-square', routerLink: ['/settings/Assurances'] },
                            { label: 'Özel Notlar', icon: 'pi pi-fw pi-book', routerLink: ['/settings/table/customernotes'] }, //Müşterinin kişi müşterileri için
                            { label: 'Kullanıcı Kılavuzları', icon: 'pi pi-fw pi-book', routerLink: ['/operator/userGuides'] }, // Sayfaların kullanımı konusunda bilgilendirici kılavuzların tanımlandığı ve tanımlı kılavuzların listelendiği sayfaya yönlendiren link
                            { separator: true },
                            { label: 'Parametreler', icon: 'pi pi-fw pi-check-square', routerLink: ['/parameters'] },
                            { separator: true },
                            { label: 'Counters Tanımları', icon: 'pi pi-fw pi-building', routerLink: ['/settings/counters-table']},
                            { label: 'Ülke Tanımları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/table/countries'] },

                            { label: 'Şehir Tanımları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/table/cities'] },
                            { label: 'İlçe Tanımları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/table/towns'] },
                            { label: 'Mahalle Tanımları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/districts'] },
                            { label: 'Bölge Tanımları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/areas'] },
                            { label: 'Acente Bölge Tanımları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/agency-region-definitions'] },
                            { separator: true },
                            { label: 'Çalışma Saatleri', icon: 'pi pi-fw pi-tag', routerLink: ['/settings/workingHours'] },
                            { label: 'Resmi Tatiller', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/holidays'] },
                            { separator: true },
                            { label: 'Seviye Tanımları', icon: 'pi pi-fw pi-tag', routerLink: ['/settings/levels'] },
                            { separator: true },
                            { label: 'Kampanya Tanımları', icon: 'pi pi-fw pi-tag', routerLink: ['/campaigns/campaign-create'] }

                        ]
                    },
                    {
                        label: 'Tedarikçi Tanımları', icon: 'pi pi-fw pi-id-card', items: [
                            { label: 'Tedarikçiler', icon: 'pi pi-fw pi-id-card', routerLink: ['/settings/Suppliers'] },
                            { label: 'Tedarikçi Çalışanları', icon: 'pi pi-fw pi-id-card', routerLink: ['/supplier-staff'] },
                            { label: 'Tedarikçi Araçları', icon: 'pi pi-fw pi-id-card', routerLink: ['/supplier-car'] },
                            // { label: 'Tedarikçi Ücret Beklentisi', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/supplierpaymenttype'] },
                            { label: 'Tedarikçi Kota Listesi', icon: 'pi pi-fw pi-id-card', routerLink: ['/settings/supplier-quota-list'] },
                            { separator: true },
                        ]
                    },
                    {
                        label: 'Araç Tanımları', icon: 'pi pi-fw pi-car', items: [
                            { label: 'Otomobil Tanımları', icon: 'pi pi-fw pi-car', routerLink: ['/settings/auto'] },
                            { separator: true },
                            { label: 'Çekici Araç Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/towvehicletype'] },
                            { label: 'Araç Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/vehicletype'] },
                            { separator: true },
                            { label: 'Vites Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/vehiclegears'] },
                            { label: 'Yakıt Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/fueltypes'] },
                            { label: 'Araç Renkleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/vehiclecolors'] },
                            { label: 'Araç Özellikleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/vehiclefeatures'] },
                            { label: 'Segment Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/segmenttypes'] },
                            { separator: true },
                            { label: 'Segment Map Tanımları', icon: 'pi pi-fw pi-car', routerLink: ['/settings/segment-maping'] },
                        ],
                    },
                    {
                        label: "Görev Tanımları", icon: 'pi pi-fw pi-tags', items: [
                            { label: 'Takım Tanımları', icon: 'pi pi-fw pi-users', routerLink: ['/operator/team'] },
                            { label: 'Görev Kategori Tanım', icon: 'pi pi-fw pi-list', routerLink: ['/settings/table/taskcategory'] },
                            { label: 'Görev Alt Kategori Tanım', icon: 'pi pi-fw pi-list', routerLink: ['/settings/table/tasksubcategory'] },
                        ]
                    },
                    // {
                    //     label: "Ödeme Tanımları", icon: 'pi pi-fw pi-wallet', items: [
                    //         { label: 'Ödeme Başlık Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymentheader'] },
                    //         { label: 'Ödeme Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymenttype'] },
                    //         { label: 'Ödeme Detay Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymentdetailtype'] },
                    //         // { label: 'Geri Ödeme Sebepleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/reasonrefund'] },
                    //         { label: 'Ödeme Nedenleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymentreason'] },
                    //         // { label: 'Masaraf Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/expensetype'] },
                    //         { label: 'Para Birimleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/currency'] },
                    //         { label: 'Valör Gün', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/valuedate'] },
                    //     ]
                    // },
                    {
                        label: 'Dosya Tanımları', icon: 'pi pi-fw pi-folder', items: [
                            { label: 'Bildirim Tipleri', icon: 'pi pi-fw pi-book', routerLink: ['/settings/table/occurrencetype'] },
                            { label: 'Bildirim Alt Tipleri', icon: 'pi pi-fw pi-book', routerLink: ['/settings/table/occurrencesubtype'] },
                            { separator: true },
                            { label: 'Dosya Tipleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/filetype'] },
                            { label: 'Dosya Durumları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/filestatus'] },
                            { label: 'Tekrar Dosya Açma Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/fileOpenReason'] },
                            { label: 'Dosya Tamamlama Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/fileCloseReason'] },
                            { label: 'Dosya İptal Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/fileCancelReason'] },
                            { separator: true },
                            { label: 'Dosya Notu Tipleri', icon: 'pi pi-fw pi-book', routerLink: ['/settings/table/filenotetypes'] },
                            { label: 'İşlem Kanalları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/transactionchannel'] },
                            { label: 'Bildirim Kanalları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/notificationchannel'] },

                        ]
                    },

                    {
                        label: 'Hizmet Tanımları', icon: 'pi pi-fw pi-folder', items: [
                            { label: 'Hizmet Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/Services'] },
                            { label: 'Lokasyon Tipleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/locationtypes'],  },
                            { label: 'Hizmet Lokasyonları', icon: 'pi pi-fw pi-map', routerLink: ['/settings/octoservicelocation'] },
                            { label: 'Hizmet Kategorileri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/servicecategory'] },
                            { label: 'Hizmet İptal Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/serviceCancelReason'] },
                            { label: 'Hizmet Reddi Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/servicerejectionreasons'] },
                            { label: 'Hizmet Oluşturma Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/servicecreatreason'] },
                            { label: 'Tedarikçi Bölge Değiştirme Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/changesupplierreason'] },
                            { label: 'Hizmet Bilgileri Değiştirme Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/changeserviceinformationreason'] },
                            { label: 'Gün Uzatımı Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/dayextentionreason'] },
                            { label: 'Teminat Aşım Nedenleri Tanım Ekranı', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/assuranceoverrunreason'] },
                            { label: 'Teslim Adresi Değişikliği Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/changedeliveryreason'] },
                            { label: 'SLA Kategori Tanımları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/slacategories']},
                            
                            { separator: true }
                        ]
                    },

                    {
                        label: 'Fiyat/Ödeme Tanımları', icon: 'pi pi-fw pi-money-bill', items: [
                            { label: 'Fiyat Listeleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/prices'] },
                            { label: 'Fiyat Listeleri İptal Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/pricecancelreason'] },
                            { label: 'Yüklenici Tipleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/payertype'] },
                            { label: 'Ödeme Birimi Tipleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/currencytype'] },
                            { label: 'Ödeme Başlık Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymentheader'] },
                            { label: 'Ödeme Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymenttype'] },
                            { label: 'Ödeme Detay Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/paymentdetailtype'] },
                            // { label: 'Geri Ödeme Sebepleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/reasonrefund'] },
                            { label: 'Ödeme Nedenleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/paymentreason'] },
                            // { label: 'Masaraf Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/expensetype'] },
                            { label: 'Para Birimleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/currency'] },
                            { label: 'Valör Gün', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/valuedate'] },
                            { separator: true },
                            { label: 'İtiraz Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/objectreason'] },
                            { separator: true },
                            // { label: 'Tedarikçi Fiyat Tipleri', icon: 'pi pi-fw pi-money-bill', routerLink: ['/settings/table/supplierpaymenttype'] },


                        ]
                    },

                    {
                        label: 'Şikayet Tanımları', icon: 'pi pi-fw pi-exclamation-triangle', items: [
                            { label: 'Şikayet Nedenleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/complaintReason'] },
                            { label: 'Şikayet Kaynakları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/complaintSource'] },
                            { separator: true }
                        ]
                    },


                    {
                        label: 'Kullanıcılar ve Roller', icon: 'pi pi-fw pi-id-card', items: [
                            { label: 'Kullanıcılar', icon: 'pi pi-fw pi-users', routerLink: ['/operator/userlist'] },
                            { label: 'Kullanıcı Grupları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/usergroup'] },
                            { label: 'Rol Grupları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/rolegroup'] },
                            { label: 'Yetki Grupları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/permissiongroup'] },
                            { label: 'Yetki Tanımları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/permission'] },
                            { label: 'Rol Tanımları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/role'] },
                            { separator: true }
                        ]
                    },

                    {
                        label: 'Anket Tanımları', icon: 'pi pi-fw pi-megaphone', items: [
                            { label: 'Anketler', icon: 'pi pi-fw pi-megaphone', routerLink: ['/operator/polls'] },
                            { label: 'Anket Tipleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/pollType'] },
                            { separator: true }
                        ], visible:await this.checkModule("Anket Modülü", 'v2')
                    },

                    {
                        label: 'Durum Tanımları', icon: 'pi pi-fw pi-info-circle', items: [
                            { label: 'Durumlar', icon: 'pi pi-fw pi-tag', routerLink: ['/settings/status'] },
                            { label: 'Durum Grup Tanımları', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/statusgroup'] },
                            { label: 'Durum Grup Özetleri', icon: 'pi pi-fw pi-tags', routerLink: ['/settings/table/statusgroupsummary'] },

                            //{label: 'E-Posta Formatları', icon: 'pi pi-fw pi-envelope', routerLink: ['/settings/emails']},
                            // {label: 'Sorgu Tipleri', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/querytype']},
                            //{label: 'Tedarikçi Operasyon Durumları', icon: 'pi pi-fw pi-comment', routerLink: ['/settings/table/supplieroperationstatus']}
                        ]
                    },
                    {
                        label: 'Zeplin Tanımları', icon: 'pi pi-fw pi-briefcase', items: [
                            { label: 'Grup Kodu Tanımları', icon: 'pi pi-fw pi-tag', routerLink: ['/settings/zeplinGroupCodes'] },
                            { label: 'Ofis Tanımları', icon: 'pi pi-fw pi-tag', routerLink: ['/settings/zeplinOffices'] },

                        ],
                    },
                    {
                        label: 'MIP Tanımları', icon: 'pi pi-fw pi-book', items: [
                            { label: 'Mip Kategori Tanımları', icon: 'pi pi-fw pi-tag', routerLink: ['/settings/mipAndOctopus'] },
                        ],
                    }
                ]
            }
        ];

        const response= this.permissionsService.setMenuVisibility(menu);
        return response

    }

    private async checkModule(moduleName, versionName) {
        if (typeof this.versions[moduleName] === 'string') {
            return this.versions[moduleName] === versionName;
        }
        const module = await this.versionService.getVersion(moduleName).toPromise();
        if (!module) {
            return false;
        }
        this.versions[moduleName] = module.version;
        return module.version === versionName;
    }

    async getReportsMenu() {
        const menu= [
            {
                label: 'Raporlar', icon: 'pi pi-fw pi-book', items: [
                    {
                        label: 'Finans Raporu', icon: 'pi pi-fw pi-wallet', routerLink: ['/operator/reporter']
                    },
                    {
                        label: 'Müşteri Raporu', icon: 'pi pi-fw pi-copy', routerLink: ['/operator/customerreports']
                    },
                    {
                        label: 'Tedarikçi Fiyat Raporu', icon: 'pi pi-fw pi-money-bill', routerLink: ['/operator/supplierpricereport']
                    },
                    {
                        label: 'Tedarikçi Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/supplierreport']
                    },
                    {
                        label: 'Tedarikçi Hizmet Gösterge Paneli', icon: 'pi pi-fw pi-box', routerLink: ['/operator/supplierServiceDashboard']
                    },
                    {
                        label: 'Memnuniyet Anket Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/surveyreport'], visible: await this.checkModule("Anket Modülü", 'v2')
                    },
                    {
                        label: 'Hizmet Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/servicesReport'],
                    },
                    {
                        label: 'Hizmet Durum Değişim Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/service-statu-change-report'],

                    },
                    {
                        label: 'İkame Hizmeti Gün Uzama Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/ikm-service-day-extentaion'],
                    },
                    {
                        label: 'Çekici Hizmet Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/towing-service-report'],
                    },
                    {
                        label: 'Asistans Dosyası Detay Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/assistanceFileReport'], visible: true
                    },
                    {
                        label: 'Hizmet Talebi Detay Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/serviceRequestDetailReport'], visible: true
                    },
                    {
                        label: 'Kullanıcı Bazlı Organizasyon Detay Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/userBasedOrganisationReport'], visible: true
                    },
                    {
                        label: 'Kullanıcı Bazlı Özet Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/userBasedSummaryReport'], visible: true
                    },
                    {
                        label: 'İz Raporu', icon: 'pi pi-fw pi-box', routerLink: ['/operator/markReport'],

                    },
                    {
                        label: 'Google Logs', icon: 'pi pi-fw pi-google', routerLink: ['/operator/googleLogs'],

                    },
                    {
                        label: 'SLA Kategori Gösterge Paneli', icon: 'pi pi-fw pi-box', routerLink: ['/operator/slaCategoriesDashboard']
                    },
                    {
                        label: 'Dashboard Anomali Raporları', icon: 'pi pi-fw pi-box', routerLink: ['/operator/dashboard-anomaly'],
                       // visible: this.authPermissionService.permissionCheck('Show.DashbordAnomalyReport', 'isList')
                    },
                    {
                        label: 'Teminatsız Hizmetler', icon: 'pi pi-fw pi-box', routerLink: ['/operator/dashboard-assurance'],
                    }
                    /*
                    {
                        label: 'Durum Raporları', icon: 'pi pi-fw pi-book', routerLink: ['/operator/reportSchene']
                    }*/
                ]
            }
        ]
       const response= this.permissionsService.setMenuVisibility(menu);
       return response
    }

   async getSupplierMenu() {
        const menu= [
            {
                label: 'Ana Sayfa', icon: 'pi pi-fw pi-home', routerLink: ['/supplier-operations/home'],
            },
            {
                label: 'Hizmet Listesi', icon: 'pi pi-fw pi-list', routerLink: ['/supplier-operations/service-list']

            },
            {
                label: 'Operasyon Kontrol', icon: 'pi pi-fw pi-list', routerLink: ['/supplier-operations/operation-control']
            },
            {
                label: 'Çalışanlar', icon: 'pi pi-fw pi-users', routerLink: ['/supplier-staff']
            },
            {
                label: 'Tedarikçi Araçları', icon: 'pi pi-fw pi-car',
                items: [
                    { label: 'Araç Tanımla', icon: 'pi pi-fw pi-car', routerLink: ['/supplier-car'] },
                    { label: 'Özelleştirilmiş Marka Model Bilgileri', icon: 'pi pi-fw pi-car', routerLink: ['/supplier-operations/customizeSupplierVehicle'] },

                ]
            },
            {

                label: 'Görev Listesi',
                icon: 'pi pi-fw pi-history',
                routerLink: ['/operator/tasks']

            },
            {
                label: 'Muhasebe', icon: 'pi pi-fw pi-briefcase',
                items: [
                    //{ label: 'Ödeme Operasyon', icon: 'pi pi-fw pi-money-bill', routerLink: ['/supplier-operations/payment-operations'] },
                    { label: 'Ödeme Mutabakat', icon: 'pi pi-fw pi-verified', routerLink: ['/operator/payment-control'], visible:await this.checkModule("Fiyat Modülü", 'v2') },
                    { label: 'Fatura Takip', icon: 'pi pi-fw pi-ticket', routerLink: ['/supplier-operations/bill-list'] },
                ]
            }


        ];

        const response=this.permissionsService.setMenuVisibility(menu);
        return response;
    }
}

