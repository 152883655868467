import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutoService } from '../../../services/settings/auto.service';
import { AuthService } from '../../../services/auth/auth.service';
import { SupplierCarService } from '../../../services/settings/supplier-car.service';
import { ISupplierCar, SupplierCarDto } from '../../../domain/supplier-car';
import { AppMessageService } from '../../../services/common/app-message.service';
import { OctoTableMasterService } from '../../octo-table/services/octo-table-master.service';
import { SelectItem } from 'primeng/api';
import { ICity } from '../../../domain/location';
import { CityService } from '../../../services/settings/city.service';
import { SupplierService } from '../../../services/settings/supplier.service';
import { ISubscriberService } from '../../../domain/subscriber-service';

@Component({
  selector: 'app-supplier-car-dialog',
  templateUrl: './supplier-car-dialog.component.html',
  styleUrls: ['./supplier-car-dialog.component.scss']
})

export class SupplierCarDialogComponent implements OnInit {
  form: FormGroup;
  carDialog: boolean = false;
  vehicleModels: any[] = [];
  vehicleYears: any[] = [];
  brandOptions: any[] = [];
  segmentTypes: any[] = [];
  segment = { label: '', value: '' };
  cities: any[];
  selectedCities: any[];
  cityCodes: string[] = [];
  cars: SupplierCarDto = {};
  vehiclegears: SelectItem[] = [];
  fueltypes: SelectItem[] = [];
  vehiclecolors: SelectItem[] = [];
  vehiclefeatures: SelectItem[] = [];
  allCities: ICity[];
  suppliersLists: SelectItem[];
  supplierCustomizeVehicles: SelectItem[];
  currentCarId: string;
  carsData: SupplierCarDto;
  @Output() onNewCarAdded = new EventEmitter <ISupplierCar>();
  @Output() onNewCarEdit = new EventEmitter <SupplierCarDto>();
  @Input() isSupplier: string;
  @Output() reloadEvent = new EventEmitter<void>();
  @Input() service: ISubscriberService;
  @Input() selectedSupplier: any;
  @Input() special: boolean=false;
  branches = [];

  isActive: boolean = true;

  constructor(
    private cityService: CityService,
    private autoService: AutoService,
    private fb: FormBuilder,
    private auth: AuthService,
    private messageService: AppMessageService,
    private supplierCarService: SupplierCarService,
    private octoTableService: OctoTableMasterService,
    private supplierService: SupplierService,
  ) {
    this.form = this.fb.group({
      supplier: new FormControl(''),
      selectedSupplier: new FormControl('', Validators.required),
      plateCityCode: new FormControl('', Validators.required),
      plateNumber: new FormControl('', Validators.required),
      chassisNumber: new FormControl('', [Validators.pattern('^[A-HJ-NPR-Z0-9]*$')]),
      brand: new FormControl('', Validators.required),
      vehicleModel: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      segment: new FormControl('', Validators.required),
      segmentName: new FormControl(''),
      vehicleGear: new FormControl(''),
      fuelType: new FormControl(''),
      vehicleColor: new FormControl(''),
      vehicleKm: new FormControl(''),
      engineCapacity: new FormControl(''),
      enginePower: new FormControl(''),
      vehicleFeatures: new FormControl(''),
      //isActive: new FormControl<boolean>(true),
      supplierBranch: new FormControl({value: '', disabled: true})
    });

  }

  translateFieldName(fieldName: string): string {
    const fieldNames = {
      plateNumber: "Plaka Numarası",
      brand: "Marka",
      vehicleModel: "Model",
      year: "Yıl",
      segment: "Segment",
      plateCityCode: "Plaka İl Kodu",
      selectedSupplier: "Tedarikçi",
    };

    return fieldNames[fieldName] || '';
  }

  updateOrSave() {
    let errors = [];
    if (!this.form.valid) {
      for (const field in this.form.controls) {
        if (!this.form.get(field).valid) {
          const fieldName = this.translateFieldName(field);
          errors.push(`${fieldName}`);
        }
      }

      if(errors.length){
        this.messageService.reportError(`${errors.join(', ')} alanları zorunludur.`);
      }

      return;
    }

    if (this.cars?._id) {
      this.editSupplierCar();
    } else {
      this.addSupplierCar();
    }
  }

  async ngOnInit(): Promise<void> {
    this.suppliersLists = await this.supplierService.getSupplierItems();
    this.getCities();
    this.autoService.getAllOuto({ isActive: true }).subscribe((res) => {
      this.brandOptions = res;
    });

    this.octoTableService
      .getMultiSelectData({
        entity: "segmenttypes",
        options: {
          label: "name",
          value: "_id",
          filters: [{ field: "isActive", value: true }],
        },
      })
      .subscribe((res) => {
        this.segmentTypes = res;
      });

    this.octoTableService
      .getMultiSelectData({
        entity: "vehiclegears",
        options: {
          label: "name",
          value: "_id",
          filters: [{ field: "isActive", value: true }],
        },
      }).subscribe((res) => {
        this.vehiclegears = res;
      });

    this.octoTableService
      .getMultiSelectData({
        entity: "fueltypes",
        options: {
          label: "name",
          value: "_id",
          filters: [{ field: "isActive", value: true }],
        },
      }).subscribe((res) => {
        this.fueltypes = res;
      });
    this.octoTableService
      .getMultiSelectData({
        entity: "vehiclecolors",
        options: {
          label: "name",
          value: "_id",
          filters: [{ field: "isActive", value: true }],
        },
      }).subscribe((res) => {
        this.vehiclecolors = res;
      });
    this.octoTableService
      .getMultiSelectData({
        entity: "vehiclefeatures",
        options: {
          label: "name",
          value: "_id",
          filters: [{ field: "isActive", value: true }],
        },
      }).subscribe((res) => {
        this.vehiclefeatures = res;
      });
  }

  getCities() {
    this.cityService.getAllCities()
      .subscribe(response => {
        this.cities = response.rows.map(city => city.code);
      });
  }
  closeDialog() {
    this.form.reset();
    this.carDialog = false;
  }

  openCarDialog(cars?: SupplierCarDto, selectedSupplierId?:string) {
    if (cars && cars.supplier && (cars?.supplier as any)._id) {
      this.supplierService.getSupplierBranches((cars?.supplier as any)._id).subscribe((branches) => {
        this.branches = branches.map(branch => ({label: branch.branchName, value: branch._id}));
        if (this.branches.length > 0) {
          this.form.get('supplierBranch').enable(); // Eğer şube varsa şube seçimini enable yap
        }else{
          this.form.get('supplierBranch').disable();
        }
      });
    }
    this.selectedSupplier=this.selectedSupplier ? this.selectedSupplier : this.auth.getUserSupplierId();
    this.form.reset();
    this.isActive = true;

    //  this.selectedSupplier = cars ? (cars?.supplier as any)._id : '';
    this.cars = cars ? cars  : { isActive: true };
    this.carDialog = true;

    this.currentCarId = cars?._id;
    this.cars._id = cars?._id
    this.carsData = cars

    const vehicleModelId = cars?.vehicleModel;
    this.vehicleModels = cars?.brand?.autoModels;

    const matchedModel = cars?.brand?.autoModels?.find(model => model._id === vehicleModelId);
    this.vehicleYears = matchedModel?.segments
    const matchedYear = matchedModel?.segments?.find(segment => segment.year === cars?.year);

    this.form.patchValue({
      _id: this.cars?._id,
      plateCityCode: cars?.plateCityCode,
      plateNumber: cars?.plateNumber,
      vehicleColor: cars?.vehicleColor,
      brand: cars?.brand?._id,
      vehicleModel: cars?.matchedModel?._id,
      year: matchedYear,
      segment: cars?.segment,
      segmentName: cars?.segmentName,
      vehicleGear: cars?.vehicleGear,
      fuelType: cars?.fuelType,
      vehicleKm: cars?.vehicleKm,
      engineCapacity: cars?.engineCapacity,
      enginePower: cars?.enginePower,
      vehicleFeatures: cars?.vehicleFeatures,
      chassisNumber: cars?.chassisNumber,
      supplier: (cars?.supplier as any)?._id || this.selectedSupplier,
      selectedSupplier: (cars?.supplier as any)?._id || this.selectedSupplier,
      //isActive: cars?.isActive,
      supplierBranch: cars?.supplierBranch
    });

    if (this.isSupplier) {
      this.form.controls['selectedSupplier'].disable();
      this.onSupplierChange({value: this.selectedSupplier});
      this.form.get('supplierBranch').enable();
    } else {
      this.form.controls['selectedSupplier'].enable();
    }

    this.isActive = cars && cars?.isActive ? cars.isActive : true;
  }

  brandChange(selectedBrand) {
    this.form.controls.segmentName.setValue('');
    this.form.controls.segment.setValue('');
    this.form.controls.vehicleModel.setValue('');
    if (selectedBrand.value) {
      this.form.controls.vehicleModel.enable();
      this.form.controls.year.enable();
    } else {
      this.form.controls.vehicleModel.disable();
      this.form.controls.year.disable();
    }

    this.form.controls.year.setValue({
      segment: '',
      year: '',
    });

    const vehicle: any = this.brandOptions.find(
      (e: any) => e._id === selectedBrand.value
    );
    this.vehicleModels = vehicle ? vehicle.autoModels : [];
  }

  vehicleModelChange(selectedModel) {
    this.form.controls.segmentName.setValue('');
    this.form.controls.segment.setValue('');

    this.form.controls.year.setValue({
      segment: '',
      year: '',
    });
    const vehicleModel: any = this.vehicleModels.find(
      (e: any) => e._id === selectedModel.value
    );
    this.vehicleYears = vehicleModel.segments;
  }

  onHide(){
    this.form.reset();
    if(!this.special){
      this.selectedSupplier = undefined;
    }
    
  }
  onSupplierChange(input: any) {
    const selectedSupplier = input.value ? input.value : input;
    this.selectedSupplier = selectedSupplier;
    this.form.get('supplierBranch').disable();
  
    if (selectedSupplier) {
      this.supplierService.getSupplierBranches(selectedSupplier).subscribe((branches) => {
        this.branches = branches.map(branch => ({label: branch.branchName, value: branch._id}));
        if (this.branches.length > 0) {
          this.form.get('supplierBranch').enable();
        }
      });
    }
  }
  
  vehicleYearChange(selectedYear) {
    const supplierId = this.selectedSupplier ? this.selectedSupplier : this.auth.getUserSupplierId();
    this.supplierService.getCustomizedVehicle(supplierId).subscribe(
      (data) => {
        const supplierCustomizeVehicles = data.customizeVehicles;
        const customizeVehicles = supplierCustomizeVehicles.find((t: any) => {
          return (
            t.brand?._id === this.form.controls.brand.value &&
            t.model === this.form.controls.vehicleModel.value &&
            t.year === selectedYear.value.year
          );
        });

        if (customizeVehicles) {
            const segment=this.segmentTypes.find(s => s.value === customizeVehicles.segment.segment._id)
            if(segment){
                this.form.controls.segmentName.setValue(segment?.label);
                this.form.controls.segment.setValue(segment?.value);
            }

        } else {
          const modelOption = this.vehicleModels.find((model) => model._id === this.form.controls.vehicleModel.value);

          const selectedSegment = modelOption.segments.find(
            (segment) => segment.year === selectedYear.value.year
          );

          this.form.controls.segmentName.setValue(this.segmentTypes.find(
            (type) => type.value === selectedSegment.segment
          ).label);
          this.form.controls.segment.setValue(selectedSegment.segment);
        }
      },
      (error) => {
        console.error("getCustomizedVehicle error:", error);
      }
    );
  }

  isValid() {
    return this.form.valid;
  }

  private addSupplierCar(): void {
    const formData = { ...this.form.value, year: this.form.controls.year.value.year };
    const supplier = this.isSupplier ? this.auth.getUserSupplierId() : formData.selectedSupplier;
    formData.supplier = supplier;
    formData.isActive = this.isActive;

    this.supplierCarService
      .addSupplierCar(formData)
      .subscribe(
        (car: any) => {
          const supplierCar: ISupplierCar = car;
          this.onNewCarAdded.emit(supplierCar);
          this.messageService.successToast('Başarılı','İşleminiz gerçekleşti.');
          this.carDialog = false;
        },
        (err: any) => {
          const errorMessage = err.error?.message || 'Veriler İletilemedi';
          this.messageService.reportError(errorMessage);
        }
      );

  }

  private editSupplierCar(): void {
    const formData = this.form.value;
    const supplier = this.isSupplier ? this.auth.getUserSupplierId() : formData.selectedSupplier;
    formData.supplier = supplier
    formData.year = this.cars?.year
    formData.isActive = this.isActive;
    this.supplierCarService
      .editSupplierCar(this.cars?._id, formData)
      .subscribe(
        (car: any) => {
          this.onNewCarEdit.emit(car);
          this.messageService.successToast('Güncellendi','İşleminiz gerçekleşti.');

          this.carDialog = false;
        },
        (err: any) => {
          const errorMessage = err.error?.message || 'Veriler Güncellenemedi';
          this.messageService.reportError(errorMessage);
        }
      );
  }

  onPlateNumberChange() {
    const turkishToEnglish = {
      'ç': 'C', 'Ç': 'C', 'ğ': 'G', 'Ğ': 'G', 'ı': 'I', 'İ': 'I', 
      'ö': 'O', 'Ö': 'O', 'ş': 'S', 'Ş': 'S', 'ü': 'U', 'Ü': 'U'
    };
  
    // Form kontrolündeki değeri al
    const plateNumberControl = this.form.get('plateNumber');
    
    if (plateNumberControl) {
      let plateNumber = plateNumberControl.value || '';
      
      // Değeri dönüştür ve ayarla
      plateNumber = plateNumber
        .replace(/[çÇğĞıİöÖşŞüÜ]/g, char => turkishToEnglish[char])  // Türkçe karakterleri dönüştür
        .replace(/\s+/g, '')  // Boşlukları kaldır
        .toUpperCase();  // Harfleri büyük yap
  
      // Güncellenmiş değeri form kontrolüne geri yaz
      plateNumberControl.setValue(plateNumber, { emitEvent: false });
    }
  }



}
