import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriberServiceStatusEnum } from 'src/app/domain/decorators/subscriber-service-status.decorator';
import { IListEnvelope } from 'src/app/domain/list.interface';
import { IServiceAnswer } from 'src/app/domain/service';
import { environment } from 'src/environments/environment';
import { LogKeys } from '../../domain/log.keys.interface';
import { SubscriberServiceDto, ISubscriberService, InChargeUserDto, ISubscriberServiceFilterDto } from '../../domain/subscriber-service';
import { AuthService } from '../auth/auth.service';
import { ISurvey } from '../../domain/survey.interface';
import { ILevel } from '../../pages/settings/levels/levels.interface';
import { AddressInterface } from '../../components/address/intefaces/address-autocomplete.interface';

@Injectable({
    providedIn: 'root'
})
export class SubscriberServiceService {

    constructor(private http: HttpClient, public auth: AuthService) { }

    getAllServices(page: number, limit: number, status: string, customer: string, text: string, dateFilter: string,
        filters: { cost?: boolean, serviceTypes?: string[], dateFilter?: {}, supplier?: string, serviceStatus?: string[], cities?: string[] }, all?: boolean) {
        let query = `?&page=${page ?? ''}&limit=${limit ?? ''}&status=${status ?? ''}&text=${text ?? ''}`;
        if (all) {
            query += `&all=${all ?? ''}`;
        }
        if (customer) {
            query += `&customer=${customer ?? ''}`;
        }
        if (dateFilter) {
            query += `&dateFilter=${dateFilter ?? ''}`;
        }
        return this.http.post<IListEnvelope<ISubscriberService>>(`${environment.url}subscriberServices/all?${query}`, filters);
    }

    getServiceById(id: string) {
        return this.http.get<ISubscriberService>(`${environment.url}subscriberServices/${id}`);
    }

    getServiceDetailLogsById(id: string) {
        return this.http.get<any[]>(`${environment.url}subscriberServices/getServiceDetailLogs/${id}`);
    }

    getServiceForSupplier(id: string) {
        return this.http.get<ISubscriberService>(`${environment.url}subscriberServices/getServiceForSupplier/${id}`);
    }

    //Dosya listesine daha az veri getirmesi için yazılan yeni servis
    getAllServicesForFileList(page: number, limit: number, status: string, customer: string, text: string, dateFilter: string,
        filters: { cost?: boolean, serviceTypes?: string[], dateFilter?: {}, supplier?: string, serviceStatus?: string[], cities?: string[] }, all?: boolean) {
        let query = `?&page=${page ?? ''}&limit=${limit ?? ''}&status=${status ?? ''}&text=${text ?? ''}`;
        if (all) {
            query += `&all=${all ?? ''}`;
        }
        if (customer) {
            query += `&customer=${customer ?? ''}`;
        }
        if (dateFilter) {
            query += `&dateFilter=${dateFilter ?? ''}`;
        }
        return this.http.post<IListEnvelope<ISubscriberService>>(`${environment.url}subscriberServices/allForFileList?${query}`, filters);
    }

    getServicesForSupplier(page: number, limit: number, all?: boolean, filter?: {
        supplier?: string,
        service?: string,
        serviceStatus?: string,
        text?: string,
        opsiyon?: string,
        fromCity?: string,
        fromTown?: string,
        startDate?: string,
        endDate?: string,
        sort?: number,
        statuses?: string,
    }): Observable<IListEnvelope<any>> {
        let query = `?&page=${page ?? null}&limit=${limit ?? null}&all=${all ?? null}`;
        return this.http.post<IListEnvelope<any>>(`${environment.url}subscriber-services-list/supplierOperationControl?${query}`, filter);
    }

    addService(service: SubscriberServiceDto, sendMail?: boolean) {
        return this.http.post<ISubscriberService>(`${environment.url}subscriberServices/create${sendMail ? '?sendMail=true' : ''}`, service);
    }

    editService(id: string, service: SubscriberServiceDto) {
        return this.http.put<ISubscriberService>(`${environment.url}subscriberServices/${id}`, service);
    }

    detailService(id: string) {
        return this.http.get<ISubscriberService>(`${environment.url}subscriberServices/${id}`);
    }

    deleteService(id: string) {
        return this.http.delete(`${environment.url}subscriberServices/${id}`);
    }

    getAllBySupplierIdAndStatus(id: string, date: string, status?: string[], text?: string) {
        return this.http.post<ISubscriberService[]>(`${environment.url}subscriberServices/bySupplierIdAndStatus/${id}`, { date: date, text: text, status: status });
    }

    getAllBySupplierId(id: string, date: string = "1") {
        return this.http.get<ISubscriberService[]>(`${environment.url}subscriberServices/bySupplierId/${id}/${date}`);
    }

    updateStaff(id: string, staff: string, operationStatus: string) {
        const data = { staff, operationStatus };
        return this.http.put(`${environment.url}subscriberServices/updateStaff/${id}`, data);
    }
    removeStaff(id: string) {
        return this.http.put(`${environment.url}subscriberServices/remove-staff/${id}`, {});
    }

    updateServiceStatus(id: string, status: SubscriberServiceStatusEnum, cancelReason?: string, rejectReason?: string, rejectDescription?: string) {
        return this.http.put<ISubscriberService>(`${environment.url}subscriberServices/updateServiceStatus/${id}/${status}`, { cancelReason, rejectReason, rejectDescription });
    }

    //Duplicate/
    /*
    updateStatus(id: string, status: string) {
        return this.http.put(`${environment.url}subscriberServices/updateStatus/${id}/${status}`, {});
    }*/

    updateServiceExperDamageFile(id: string, expertiseFile: string, damageFile: string, damageControlDescription: string, reasonOfLoss: string, reasonOfLossCode: string) {
        return this.http.post<ISubscriberService>(`${environment.url}subscriberServices/updateServiceExperDamageFile/${id}`, { expertiseFile, damageFile, damageControlDescription, reasonOfLoss, reasonOfLossCode });
    }

    updateServiceDetails(id: string, supplier: string, serviceDetails: any, sendMail?: boolean, dontSendSms?: boolean) {
        return this.http.post<ISubscriberService>(`${environment.url}subscriberServices/updateServiceDetails/${id}${sendMail ? '?sendMail=true' : ''}`, { serviceDetails, supplier, dontSendSms });
    }

    updateServiceDetailLogs(id: string, serviceDetailLogs: any) {
        return this.http.post<ISubscriberService>(`${environment.url}subscriberServices/updateServiceDetailLogs/${id}`, serviceDetailLogs);
    }

    updatePayment(id: string, payer: string, amount: number,
        priceInfo: {
            priceType: string, km?: number, day?: number,
            paymentRecord?: { kdvRate: number, withoutKdv: number, kdvAmount: number, totalAmount: number, billNo?: string, billDate?: Date, po?: string, values?: { km?: number, day?: number } },
            extraCosts?: { name: string, withoutKdv: number, kdvRate: number, total: number }[],
            notes?: { type: string, done: boolean, note: string, supplier?: string }[],
            fatura?: { faturaNo?: string, faturaDate?: Date, faturaTotal?: number, kdvRate?: number, withoutKdv?: number, kdvAmount?: number, tev?: string },
            logs?: { tag: string, name: string, email: string, date: Date, userId: string, note?: string }[],
            status?: string
        },

        terms?: {
            currency?: string,
            kmLimit?: number,
            overLimitPrice?: number,
            price?: number,
            priceType?: string
        },
        mode?: 'fatura' | 'ödeme') {
        let data;
        data = { payer, amount, priceInfo, terms };
        if (mode) { data = { ...data, mode: mode }; };
        return this.http.post(`${environment.url}subscriberServices/updatePayment/${id}`, data);
    }

    updateSpecificAnswers(id: string, answers: any) {
        return this.http.post<IServiceAnswer[]>(`${environment.url}subscriberServices/updateSpecificAnswers/${id}`, answers);
    }

    saveSurvey(serviceId: string) {
        return this.http.post<ISubscriberService>(`${environment.url}subscriberServices/saveSurvey/${serviceId}`, "");
    }

    sendServiceSMS(id: string, phone: string, fileNo: string, policyNo: string, serviceType: string, personName: string, fromEmail: string, serviceNo: string, uid: string) {
        const data = { phone, fileNo, policyNo, serviceType, serviceNo, uid, personName, fromEmail }
        return this.http.post<ISubscriberService>(`${environment.url}subscriberServices/send-sms/${id}`, data);
    }

    getActiveSurvey(serviceId: string) {
        return this.http.get<{
            questions: any[],
            activeSurvey: ISurvey,
            answered: boolean
        }>(`${environment.url}subscriberServices/getActiveSurvey/${serviceId}`);
    }

    updateSurveyAnswers(serviceId: string, surveyId: string, qas: any[]) {
        return this.http.patch<{ success: boolean }>(`${environment.url}subscriberServices/answerSurvey`, {
            serviceId,
            surveyId,
            qas
        });
    }



    /**
     *
     * @param selectedRows PriceInfo larının güncellenceği SubscriberService[]
     * @param billNo
     * @param billDate
     * @returns ISubscriberService[]
     */
    updatePriceInfo(selectedRows: ISubscriberService[], billNo?: string, billDate?: Date, tag?: string) {
        let data = [];
        // Hatalar var ! (Fatura girişinde tarife gidiyor, yeni yaz)
        selectedRows.forEach(row => {
            // Burada objeye log adında ek alan koyulmuş, bu alana serverda date ekleniyor, sonra logs a pushlanıyor! (Sonradan incele biraz dolanbaçlı olmuş)
            const log = { tag: tag, name: this.auth.getUserName(), email: this.auth.getUserEmail(), userId: this.auth.getUserId() };
            const obj = { id: "", priceInfo: {}, log: log, isSupplierApprove: undefined };
            if (tag === 'Tedarikçi Onayı') {
                obj.isSupplierApprove = true;
            }
            if (billNo) { row.priceInfo.paymentRecord.billNo = billNo; };
            if (billDate) { row.priceInfo.paymentRecord.billDate = billDate; };
            obj.id = row._id;
            obj.priceInfo = row.priceInfo;
            data.push(obj);
        });
        return this.http.post<ISubscriberService[]>(`${environment.url}subscriberServices/addBill`, data)
    }


    GetAllBySupplierId(page: number, limit: number, status?: string, operationStatus?: string, text?: string, startDate?: Date, endDate?: Date, customer?: string, supplier?: string) {
        let query = `?&page=${page ?? ''}&customer=${customer ?? ''}&supplier=${supplier}&limit=${limit ?? ''}&status=${status}&operationStatus=${operationStatus ?? ''}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&text=${text ?? ''}`;
        return this.http.get<ISubscriberService[]>(`${environment.url}subscriberServices/getAll/all?${query}`);
    }
    updateKm(start: string, origin: string, destination: string, id: string, priceInfo?) {
        if (priceInfo === undefined) {
            priceInfo = { km: '' };
        }
        const data = { priceInfo: priceInfo, origin: origin, destination: destination, start: start }
        return this.http.post<any>(`${environment.url}occurrenceFiles/getDistance/${id}`, data);
    }

    recalculateKm(id: string, kmInfo?: { centerToOccurrence: number, occurrenceToService: number, serviceToCenter: number }) {
        return this.http.post<any>(`${environment.url}subscriberServices/recalculateKm/${id}`, kmInfo);
    }

    serviceDateSave(id: string, startedDate, endDate) {
        const dates = {
            startedDate, endDate
        }
        return this.http.put(`${environment.url}subscriberServices/saveServiceDate/${id}`, { dates });
    }
    updateVehicleDeliveryDate(id: string, date) {
        return this.http.put(`${environment.url}subscriberServices/updateVehicleDeliveryDate/${id}`, { date });
    }
    updateServiceInformationBySupplier(id: string, updatedDatas: any) {
        return this.http.put(`${environment.url}subscriberServices/updateServiceInformationBySupplier/${id}`, { updatedDatas });
    }



    getServiceReportView(
        mode: string,
        customer: string,
        data?: {
            dates?: { startDate?: Date, endDate?: Date, markDate?: Date },
            service?: string
        }
    ): Observable<any[]> {
        const param = { mode, customer, ...data }
        return this.http.post<any[]>(`${environment.url}subscriberServices/octopusServiceList`, param);
    }

    //Operasyonlar sayfası için oluışturulan servis
    getServiceOperations(page: number, limit: number, all?: boolean, filter?: {
        customer?: string,
        supplier?: string,
        fileType?: string,
        service?: string,
        serviceStatus?: string,
        text?: string,
        opsiyon?: string,
        fromCity?: string,
        fromTown?: string,
        startDate?: string,
        endDate?: string,
        sort?: number,
        inChargeUser?: string
    }): Observable<IListEnvelope<any>> {
        let query = `?&page=${page ?? null}&limit=${limit ?? null}&all=${all ?? null}`;
        return this.http.post<IListEnvelope<any>>(`${environment.url}subscriber-services-list/operationControl?${query}`, filter);
    }

    getServicePriceInfo(data: { supplier: string, service: string, mode: string, dates: { start: Date, end: Date } }) {
        return this.http.post<any[]>(`${environment.url}subscriberServices/servicePriceInfo`, data);
    }

    getCanceledServices(filter?: { serviceText?: string, fileText?: string }) {
        return this.http.post<IListEnvelope<any>>(`${environment.url}subscriberServices/getCanceledServices?all=true`, filter);
    }

    activateCanceledService(serviceId: string): Observable<ISubscriberService> {
        return this.http.put<ISubscriberService>(`${environment.url}subscriberServices/activateCanceledService`, { serviceId: serviceId });
    }

    getSupplierIntegrationList(page: number, limit: number, all?: boolean,
        filter?: {
            brand?: string,
            serviceType?: string,
            subServiceType?: string,
            city?: string,
            town?: string,
            priority?: number,
            isServiceProvided?: boolean,
            isNotEffectInvulnerability?: boolean,
            logKeys?: LogKeys
        }) {
        let query = `&page=${page ?? null}&limit=${limit ?? null}&all=${all ?? null}`;

        return this.http.post<IListEnvelope<any>>(`${environment.url}subscriberServices/getServiceIntegrationList?${query}`, filter);
    }

    getIkameIadeInfos(today: Date | string, createdBy?: string) {
        const data = { today, createdBy };
        return this.http.post<{ count: number, result: any[] }>(`${environment.url}subscriber-services-list/getIkameIade`, data);
    }

    updateDeliveryOrVehicleReturnType(data: { serviceId: string, deliveryType?: string, vehicleReturnType?: string }) {
        return this.http.post<any>(`${environment.url}subscriberServices/updateDeliveryOrVehicleReturnType`, data);
    }

    updateReturnAdressOrRetrunBranch(data: { serviceId: string, IKMVehicleReturnInfo: AddressInterface, vehicleReturnBranch?: any }) {
        return this.http.post<any>(`${environment.url}subscriberServices/updateReturnAdressOrRetrunBranch`, data);
    }

    sendTestMail() {
        return this.http.get<any>(`${environment.url}subscriber-services-list/sendTestEmail`);

    }

    updateServiceLevel(data: { serviceId: string, levelData: { level: string, isLocked: boolean } }) {
        return this.http.post<{ success: boolean, levelData: { level: ILevel, isLocked: boolean } }>(`${environment.url}subscriberServices/updateServiceLevelData`, data);
    }

    updateInChargeUser(serviceId: string, inChargeUserId: string): Observable<any> {
        const data = { inChargeUser: inChargeUserId };
        return this.http.patch<any>(`${environment.url}subscriberServices/updateInChargeUser/${serviceId}`, data);
    }

    slaAggregate(filter: ISubscriberServiceFilterDto) {
        return this.http.post<any>(`${environment.url}dashboard/slaAggregate`, filter);
    }

    getServiceInfo(supplierId) {
        return this.http.get<any>(`${environment.url}supplier-subscriber-services-list/getServiceInfo/${supplierId}`);
    }

    verifiedVehicle(id: string, verifiedVehicleData: any) {
        return this.http.put<any>(`${environment.url}subscriberServices/verifiedVehicle/${id}`, verifiedVehicleData);
    }

    vehicleDateVerification(id: string, data: any) {
        return this.http.put<any>(`${environment.url}subscriberServices/vehicleDateVerification/${id}`, data);
    }


    getServices(start, end) {
        const url = `${environment.url}dashboard-assurance/findNoAssuranceLimitServices?start=${start}&end=${end}`;
  
        return this.http.get(url);
    }


    updateServiceDetailsForAssurance(serviceId, assurance) {
        return this.http.put<{ count: number, result: any[] }>(`${environment.url}dashboard-assurance/updateServiceDetailsForAssurance/${serviceId}`, assurance);
    }

}
